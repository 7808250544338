import api from "./BaseUrl";
import { updatedByInitials } from "./UserSession";

export const updateEmployee = async ({ employee }) => {
  const hireDate = employee.hireDate ? employee.hireDate : null;
  const birthday = employee.birthday ? employee.birthday : null;
  const terminationDate = employee.terminationDate
    ? employee.terminationDate
    : null;
  try {
    const response = await api.post(
      `/employees/patchEmployee/${employee.employeeId}`,
      {
        birthday: birthday,
        companyId: employee.companyId,
        companyName: employee.companyName,
        consortium: employee.consortium,
        employeeId: employee.employeeId,
        lastName: employee.lastName,
        firstName: employee.firstName,
        isDriver: employee.isDriver,
        isHidden: employee.isHidden,
        hireDate: hireDate,
        state: employee.state,
        terminationDate: terminationDate,
        employeeNotes: employee.employeeNotes,
        updatedBy: updatedByInitials,
        cdl: employee.cdl,
      }
    );

    if (response.status === 200) {
      if (employee.isDetailsOpen !== undefined) {
        employee.isDetailsOpen(false);
      }
      if (employee.isHidden === true || employee.isHidden === "true") {
        return null;
      }
      return response.data;
    }
  } catch (error) {
    console.error("Error updating employee:", error);
  }
};

// Updated to use NEW employees collection
export const addEmployee = async (employee) => {
  try {
    employee.createdBy = updatedByInitials;
    employee.terminationDate = null;
    const response = await api.post(`/employees/createEmployee/`, employee);
    if (response.status === 200 || response.status === 201) {
      return response.data; // Return the response data
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error creating new employee: ", error);
    return null;
  }
};

// Updated to use NEW employees collection
export const getCompanyEmployees = async (companyId) => {
  try {
    const allCompanyEmployees = await api.get(
      `/employees/companyEmployees/${companyId}`
    );
    return allCompanyEmployees.data;
  } catch (error) {
    console.error(`Error fetching Company #${companyId} employees: `, error);
  }
};

export const getAllEmployees = async () => {
  try {
    const allEmployees = await api.get(`/employees/allEmployees`);
    return allEmployees.data;
  } catch (error) {
    console.error("Error fetching all employees: ", error);
  }
};

// Get Active Consortium-speicifc Employees to with NEW employees collection
export const getAllConsortiumEmployees = async (consortium) => {
  try {
    const allConsortiumEmployees = await api.get(
      `/employees/consortiumEmployees/${consortium}`
    );
    return allConsortiumEmployees.data;
  } catch (error) {
    console.error(`Error fetching Company #${consortium} employees: `, error);
  }
};

// Get ONLY ACTIVE employees from ACTIVE companies
export const getAllActiveEmployeesFromActiveCompanies = async () => {
  try {
    const allActiveEmployeesFromActiveCompanies = await api.get(
      `/employees/activeEmployeesFromActiveCompanies`
    );
    return allActiveEmployeesFromActiveCompanies.data;
  } catch (error) {
    console.error(
      "Error fetching all active employees from active companies: ",
      error
    );
  }
};

// Add new function
export const getActiveEmployees = async (consortium, isDriver) => {
  try {
    let url = `/employees/active`;
    if (consortium || isDriver !== undefined) {
      url += "?";
      if (consortium) url += `consortium=${consortium}`;
      if (isDriver !== undefined) {
        if (consortium) url += "&";
        url += `isDriver=${isDriver}`;
      }
    }
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching active employees:", error);
    throw error;
  }
};
